// src/components/Help/Help.js

import React from 'react';
import './Help.css'; 

const Help = () => {
  return (
    <div className='help-container'>
      <h1>Hey.. How can I help you?</h1>
      {/* You can add more content here if needed */}
    </div>
  );
}

export default Help;
