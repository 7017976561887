//CounterDisplay.js
import React from 'react';
import CounterRow from './CounterData.js';

function CounterDisplay() {
  return (
    <div className="App">
      <CounterRow />
    </div>
  );
}

export default CounterDisplay;