import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <a href="/" className="footer-title"><img className="logo-img" src={require('./../../Images/img/Turtulogo.webp')}  alt="Turtu Logo" /></a>
            <div className="footer-container">
            
                <div className="footer-column">
                    <h4>Services</h4>
                    <ul>
                        <li><a href="#services" className="footer-link">Courier services</a></li>
                        <li><a href="#services" className="footer-link">Pick and Drop</a></li>
                        <li><a href="#services" className="footer-link">Food Delivery</a></li>
                        <li><a href="#services" className="footer-link">Cake Delivery</a></li>
                        <li><a href="#services" className="footer-link">Gift and Flower</a></li>
                        <li><a href="#services" className="footer-link">Medicine Delivery</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>Company</h4>
                    <ul>
                        <li className='footer-link' ><a href="#home" className="footer-link">Home</a></li>
                        <li><a href="about" className="footer-link">About us</a></li>
                        <li><a href="#services" className="footer-link">Services</a></li>
                        <li><Link to="/career" className="footer-link">Career</Link></li>
                        <li><Link to="/blogs" className="footer-link">Blogs</Link></li>
                        <li><Link to="/Contact" className="footer-link">Contact</Link></li>
                        <li><Link to="/team-login" className="footer-link">Work With Us</Link></li>
                   
                    </ul>
                </div>

                <div className="footer-column">
                    <h4>City</h4>
                    <ul>
                        <li><a href="#city" className="footer-link">Belagavi</a></li>
                        <li><a href="#city" className="footer-link">Hubli (coming soon)</a></li>
                        <li><a href="#city" className="footer-link">Dharwad (coming soon)</a></li>
                    </ul>
                </div>
                
                <div className="footer-column">
                    <h4>Learn More</h4>
                    <ul>
                        <li><a href="#privacy" className="footer-link">Privacy Policy</a></li>
                        <li><a href="#termsandcondition" className="footer-link">Terms and Conditions</a></li>
                    </ul>
                </div>

                <div className="footer-column footer-contact">
                <div className="footer-contact">
                    <h4>Contact</h4>
                  
                    <p className='Address-footer'>Plot No. 476, Flat No. 2, Second Floor,<br/> Shivbasav Nagar,Opposite to Siddharameshwar Science P U College.
                    <br />Belagavi. Karnataka - 590010</p>
                    <p><a href="mailto:turtuservices@gmail.com" className="footer-link Address-footer ">turtuservices@gmail.com</a></p>
                    <p>
                                <a href="tel:+917975443090" className="footer-link Address-footer">+91 7975443090 </a> <br/>
                                <a href="tel:+918747858317" className="footer-link Address-footer"> +91 8747858317</a>
                            </p>
                </div>
                </div>
            </div>

            <div className="footer-bottom">
            <p className='copywright-text'>Copyright © 2024 All Rights Reserved by:
                             <a href="#home">
                               <strong className="text-white">Turtu</strong>
                            </a>
                       </p>
                {/* <p>Copyright © 2024 All Rights Reserved by: <strong>Turtu</strong></p> */}
                {/* <div className="footer-links">
                    <a href="#">Privacy policy</a> | <a href="#">Terms and conditions</a>
                </div> */}
            </div>
        </footer>
    );
};

export default Footer;