


import React, { useState, useEffect } from "react";
import './Header.css';
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ArticleIcon from '@mui/icons-material/Article';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoginIcon from '@mui/icons-material/Login';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, Menu, MenuItem } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import {  Group as GroupIcon ,ArrowBack as ArrowBackIcon } from '@mui/icons-material';


import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [openMenu, setOpenMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [openMore, setOpenMore] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('main');

  const handleProfileClick = () => {
    setShowProfileModal(true);
    setOpenMenu(false);
  };

  // const handledropClick = () => {
  //   setCurrentMenu('more');
  // };
  
  const handleBackClick = () => {
    setCurrentMenu('main');
  };

  const menuOptions = [
    { text: "Home", icon: <HomeIcon style={{ color: 'white' }} />, path: '/#home' },
    { text: "About", icon: <InfoIcon style={{ color: 'white' }} />, path: '/about' },
    { text: "Contact", icon: <PhoneRoundedIcon style={{ color: 'white' }} />, path: '/Contact' },
    { text: "Career", icon: <WorkIcon style={{ color: 'white' }} />, path: '/career' },
    // { text: "Blogs", icon: <ArticleIcon style={{ color: 'white' }} />, path: '/blogs' },
  ];
  const moreOptions = [
    { text: "Blog", icon: <ArticleIcon style={{ color: 'white' }} />, path: '/blogs' },
    { text: "Our Team", icon: <GroupIcon style={{ color: 'white' }} />, path: '/our-team' },
  ];

  const authOptions = !isLoggedIn
    ? [
        { text: 'SignUp', path: '/signup', icon: <PersonAddIcon style={{ color: 'white' }} /> },
        { text: 'Login', path: '/Login', icon: <LoginIcon style={{ color: 'white' }} /> },
      ]
    : [
        {
          text: 'Profile',
          path: '',
          icon: profileImage ? (
            <img
              src={profileImage}
              alt="Profile"
              style={{ borderRadius: '50%', width: '30px', height: '30px' }}
            />
          ) : (
            <FaUserCircle color='white' size={23} />
          ),
          onClick: handleProfileClick,
        },
      ];

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsLoggedIn(true);
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUserEmail(decodedToken.email || 'user@example.com');
      setUserName(decodedToken.username || 'username');
      setUserPhoneNumber(decodedToken.phone_number || '1234567890');
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
    setShowProfileModal(false);
    navigate('/');
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
   
   
  };
  const handledrawerMoreClick = (e)=>
  {
    setCurrentMenu('more');
  }

  

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'active-tab' : '';
  };

  return (
    <>
      <nav style={{backgroundColor:'#02101d'}}>
         <div className="nav-logo-container">
          <a className="navbar-brand fs-2 fw-bold" href="/"><img className="logo-img" src={require('./../../Images/img/Turtulogo.webp')} alt="Turtu Logo" /></a>
        </div>
        {/*<div className="navbar-links-container">
          <Link to="/#home" className={getNavLinkClass('/#home')}>Home</Link>
          <Link to="/about" className={getNavLinkClass('/about')}>About</Link>
          <Link to="/contact" className={getNavLinkClass('/contact')}>Contact</Link>
          <Link to="/career" className={getNavLinkClass('/career')}>Career</Link>
          <Link to="/blogs" className={getNavLinkClass('/blogs')}>Blog</Link>
          
          {!isLoggedIn ? (
            <>
              <Link to="/signup" className={`navigation ${getNavLinkClass('/signup')}`}>SignUp</Link>
              <Link to="/login" className={`navigation ${getNavLinkClass('/login')}`}>Login</Link>
            </>
          ) : (
            <IconButton onClick={() => setShowProfileModal(true)} style={{ color: 'white' }}>
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  style={{ borderRadius: '50%', width: '30px', height: '30px' }}
                />
              ) : (
                <FaUserCircle size={30} />
              )}
            </IconButton>
          )}
        </div> */}
         <div className="navbar-links-container">
      <Link to="/#home" className={getNavLinkClass('/#home')}>Home</Link>
      <Link to="/about" className={getNavLinkClass('/about')}>About</Link>
      <Link to="/contact" className={getNavLinkClass('/contact')}>Contact</Link>
      <Link to="/career" className={getNavLinkClass('/career')}>Career</Link>
      
      <Button
     
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleMoreClick}
        className={getNavLinkClass('/more')}
        style={{ textTransform: 'none',fontWeight:'bold',marginRight:32 }}
      >
       More
      </Button>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/blogs" style={{color:'black',fontWeight:'bold'}} className={getNavLinkClass('/blogs')}>Blog</Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/our-team" style={{color:'black',fontWeight:'bold'}}  className={getNavLinkClass('/our-team')}>Our Team</Link>
        </MenuItem>
      </Menu>
      
      {!isLoggedIn ? (
        <>
          <Link to="/signup" className={`navigation ${getNavLinkClass('/signup')}`}>SignUp</Link>
          <Link to="/login" className={`navigation ${getNavLinkClass('/login')}`}>Login</Link>
        </>
      ) : (
        <IconButton onClick={() => setShowProfileModal(true)} style={{ color: 'white' }}>
          {profileImage ? (
            <img
              src={profileImage}
              alt="Profile"
              style={{ borderRadius: '50%', width: '30px', height: '30px' }}
            />
          ) : (
            <FaUserCircle size={30} />
          )}
        </IconButton>
      )}
    </div>
        <div className="navbar-menu-container">
           { !isLoggedIn? <PersonAddIcon  style={{ color: 'white',marginRight: 10  }}  onClick={() => navigate('/login')} />:""}
          <HiOutlineBars3 color="white" style={{ marginRight: 10 }} onClick={() => setOpenMenu(true)} />
        </div>
        {/* <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
          <Box style={{ backgroundColor: '#02101d' }} sx={{ width: 250, height: 900 }} role="presentation">
            <List>
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(item.path);
                      setOpenMenu(false); // Close the drawer after navigation
                    }}
                    className={getNavLinkClass(item.path)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText style={{ color: 'white' }} primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider />
              
              {authOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                      } else {
                        navigate(item.path);
                        setOpenMenu(false); // Close the drawer after navigation
                      }
                    }}
                    className={item.path ? getNavLinkClass(item.path) : ''}
                  >
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText style={{ color: 'white' }} primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        </Drawer> */}
         <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
    <Box style={{ backgroundColor: '#02101d' }} sx={{ width: 250, height: '100%' }} role="presentation">
      {currentMenu === 'main' ? (
        <List>
          {menuOptions.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                  setOpenMenu(false); // Close the drawer after navigation
                }}
                className={getNavLinkClass(item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText style={{ color: 'white' }} primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItemButton onClick={handledrawerMoreClick} className={getNavLinkClass('/more')}>
            <ListItemIcon><ArticleIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText style={{ color: 'white' }} primary="More" />
          </ListItemButton>
          <Divider />
          {authOptions.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  } else {
                    navigate(item.path);
                    setOpenMenu(false); // Close the drawer after navigation
                  }
                }}
                className={item.path ? getNavLinkClass(item.path) : ''}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText style={{ color: 'white' }} primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          <ListItemButton onClick={handleBackClick}>
            <ListItemIcon><ArrowBackIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText style={{ color: 'white' }} primary="Back" />
          </ListItemButton>
          {moreOptions.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                  setOpenMenu(false); // Close the drawer after navigation
                }}
                className={getNavLinkClass(item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText style={{ color: 'white' }} primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      <Divider />
    </Box>
  </Drawer>
      </nav>

      {/* Profile Modal Logic with Material UI Dialog */}
      <Dialog open={showProfileModal} onClose={() => setShowProfileModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Welcome to Turtu</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {profileImage ? (
            <img src={profileImage} alt="Profile" style={{ borderRadius: '50%', width: '100px',  height: '100px' }} />
          ) : (
            <FaUserCircle size={100} />
          )}
          {profileImage ? (
            <IconButton
              color="primary"
              component="span"
              onClick={() => document.getElementById('profileImageInput').click()}
            >
              {/* <PhotoCamera /> */}
            </IconButton>
          ) : (
            
            <IconButton
              color="primary"
              component="span"
              onClick={() => document.getElementById('profileImageInput').click()}
            >
              <PhotoLibraryIcon />

            </IconButton>
          )}
           {profileImage && (
                  <button className="btn" onClick={() => setProfileImage(null)}>Remove Image</button>
                )}
          <input
            type="file"
            id="profileImageInput"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleProfileImageChange}
          />
          <p><strong>Name:</strong> {userName}</p>
          <p><strong>Email:</strong> {userEmail}</p>
          <p><strong>Phone:</strong> {userPhoneNumber}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
          <Button onClick={() => setShowProfileModal(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
