import React from 'react'
import About from '../../Components/About/About'

const AboutPage = () => {
  return (
    <div>
        <About/>
    </div>
  )
}

export default AboutPage