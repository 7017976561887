

import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, IconButton, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import Aos from 'aos';
import 'aos/dist/aos.css';
import img from '../../Images/img/unisexAvatar.webp';
import { ArrowBackIos, ArrowForwardIos, Star } from '@mui/icons-material';

const testimonials = [
    {
        id: 1,
        name: 'Dania Shaik',
        message: 'Excellent service! The delivery was done very quickly and the delivery agent was very kind and professional too!!',
        link: 'https://g.co/kgs/ki6otv1'
    },
    {
        id: 2,
        name: 'Madhuri Yardi',
        message: 'They are the best delivery service in belgaum i hav come across for my cakes and other stuff .. Also at very reasonable rates.. Very professional!',
        link: 'https://g.co/kgs/GRYFBSE'
    },
    {
        id: 3,
        name: 'Sphoorti S',
        message: 'Quick response and fast delivery. I recommend this service if you want to deliver anything you want around the city!! Very polite delivery men.',
        link: 'https://g.co/kgs/Ku1pMhR'
    },
    {
        id: 4,
        name: 'Namrata Pawar',
        message: 'I love this delivery services its one of the best ..I have never ever got a complain from my clients saying that it ',
        link: 'https://g.co/kgs/XsZb4HG'
    },
    {
        id: 5,
        name: 'Pavitra Hegde',
        message: 'Cake was delivered intact across nearly 10kms despite of bad roads and heavy rainfall. Very professional and polite delivery executive.',
        link: 'https://g.co/kgs/xvD1XwN'
    },
    {
        id: 6,
        name: 'Vishal Kulkarni',
        message: 'Very professional and courteous service. Response time is also very fast and delivery from anywhere is done within minutes. I highly recommend them to everyone. Excellent service 👌🏻👌🏻👍🏻👍🏻',
        link: 'https://g.co/kgs/V8qHtSf'
    },
];

const TestimonialContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    position: 'relative',
}));

const TestimonialCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        maxWidth: 800,
    },
}));

const ProfileImage = styled(CardMedia)(({ theme }) => ({
    width: 70,
    height: 70,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-start',
        textAlign: 'left',
    },
}));

const RatingContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    color:"rgb(253,182,2)",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
    },
}));

const CarouselButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

const PrevButton = styled(CarouselButton)(({ theme }) => ({
    left: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        left: 0,
    },
}));

const NextButton = styled(CarouselButton)(({ theme }) => ({
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        right: 0,
    },
}));

const TestimonialCarousel = () => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevTestimonial = () => {
        const newIndex = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextTestimonial = () => {
        const newIndex = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <TestimonialContainer data-aos="zoom-out">
            <PrevButton onClick={prevTestimonial}>
                <ArrowBackIos />
            </PrevButton>
            <TestimonialCard>
                <ProfileImage
                    component="img"
                    image={img}
                   
                    alt="profile"
                />
                <CardContentStyled>
                    <Typography style={{fontWeight:'bold' ,color:'black'}} variant="body1">
                        {testimonials[currentIndex].message.length > 80
                            ? `${testimonials[currentIndex].message.slice(0, 80)}...`
                            : testimonials[currentIndex].message}
                        {testimonials[currentIndex].message.length > 80 && (
                            <a className="review_link" style={{ color: 'black' }} href={testimonials[currentIndex].link} target="_blank" rel="noopener noreferrer">more</a>
                        )}
                    </Typography>
                    <Typography variant="subtitle1" color="#d99d04" component="div">
                        {testimonials[currentIndex].name}
                    </Typography>
                    <RatingContainer>
                        {[...Array(5)].map((_, index) => (
                            <Star key={index} color="rgb(253,182,2)" />
                        ))}
                    </RatingContainer>
                </CardContentStyled>
            </TestimonialCard>
            <NextButton onClick={nextTestimonial}>
                <ArrowForwardIos />
            </NextButton>
        </TestimonialContainer>
    );
};

export default TestimonialCarousel;
