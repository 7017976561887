// import React, { useState } from 'react';
// import axios from 'axios';
// import { FaArrowLeft } from 'react-icons/fa';
// import { useNavigate } from 'react-router-dom'; 
// import './Forgot_Reset_Password.css';

// export default function ForgotPassword() {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_BASE_URL}/api/auth/web/request-password-reset`, 
//         { email },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       if (response.status === 200) {
//         setMessage('A reset password link has been sent to your email. Please check your inbox.');
//         setError('');
//       }
//     } catch (err) {
//       if (err.response) {
//         if (err.response.status === 404) {
//           setError('Email not registered. Please check and try again.');
//         } else if (err.response.status === 500) {
//           setError('Server error. Please try again later.');
//         } else {
//           setError('Failed to send reset link. Please try again.');
//         }
//         setMessage('');
//       } else {
//         setError('Network error. Please try again.');
//         setMessage('');
//       }
//     }
//   };

//   const handleBackClick = () => {
//     navigate(-1); 
//   };

//   return (
//     <>
//       <button className="back-button" onClick={handleBackClick}>
//         <FaArrowLeft /> Back
//       </button>

//       <div className="email-container">
//         <form className="email-form" onSubmit={handleSubmit}>
//           <div className="email-form-group">
//             <div className="email-label">
//               <label>Enter Email Id:</label>
//             </div>
//             <input
//               className="email-input"
//               type="email"
//               name="email"
//               value={email}
//               onChange={handleChange}
//               placeholder="Enter Email"
//               required
//             />
//           </div>
//           {message && <div className="success-message">{message}</div>}
//           {error && <div className="email-error">{error}</div>}

//           <button className="email-submit-button" type="submit">Submit</button>
//         </form>
//       </div>
//     </>
//   );
// }

import React, { useState } from 'react';
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; 
import './Forgot_Reset_Password.css';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/web/request-password-reset`, 
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setMessage('A Otp has been sent to your email. Please check your inbox.');
        setError('');
        setStep(2); // Move to OTP input step
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          setError('Email not registered. Please check and try again.');
        } else if (err.response.status === 500) {
          setError('Server error. Please try again later.');
        } else {
          setError('Failed to send reset link. Please try again.');
        }
        setMessage('');
      } else {
        setError('Network error. Please try again.');
        setMessage('');
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/web/verify-reset-otp`, 
        { email, otp },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setMessage('OTP verified. You can now reset your password.');
        setError('');
        setStep(3); // Move to password reset step
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setError('Invalid OTP. Please try again.');
        } else if (err.response.status === 500) {
          setError('Server error. Please try again later.');
        } else {
          setError('Failed to verify OTP. Please try again.');
        }
        setMessage('');
      } else {
        setError('Network error. Please try again.');
        setMessage('');
      }
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    if (new_password !== confirm_password) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/web/reset-password`, 
        { email, new_password,confirm_password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setMessage('Password has been successfully reset. Redirecting to login...');
        setError('');
        setTimeout(() => navigate('/login'), 3000); 
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setError('Invalid request. Please try again.');
        } else if (err.response.status === 500) {
          setError('Server error. Please try again later.');
        } else {
          setError('Failed to reset password. Please try again.');
        }
        setMessage('');
      } else {
        setError('Network error. Please try again.');
        setMessage('');
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1); 
  };

  return (
    <>
      <button className="back-button" onClick={handleBackClick}>
        <FaArrowLeft /> Back
      </button>

      <div className="email-container">
        <form className="email-form" onSubmit={step === 1 ? handleEmailSubmit : step === 2 ? handleOtpSubmit : handlePasswordSubmit}>
          {step === 1 && (
            <div className="email-form-group">
              <div className="email-label">
                <label>Enter Email Id:</label>
              </div>
              <input
                className="email-input"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter Email"
                required
              />
            </div>
          )}
          
          {step === 2 && (
            <div className="email-form-group">
              <div className="email-label">
                <label>Enter OTP:</label>
              </div>
              <input
                className="email-input"
                type="text"
                name="otp"
                value={otp}
                onChange={handleOtpChange}
                placeholder="Enter OTP"
                required
              />
            </div>
          )}

          {step === 3 && (
            <>
              <div className="email-form-group">
                <div className="email-label">
                  <label>Email:</label>
                </div>
                <input
                  className="email-input"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="email-form-group">
                <div className="email-label">
                  <label>New Password:</label>
                </div>
                <input
                  className="email-input"
                  type="password"
                  name="newPassword"
                  value={new_password}
                  onChange={handleNewPasswordChange}
                  placeholder="Enter New Password"
                  required 
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}"
                  title="Password must be 6 to 20 characters long, with at least one uppercase letter, one lowercase letter, and one special character."
                />
              </div>
              <div className="email-form-group">
                <div className="email-label">
                  <label>Confirm New Password:</label>
                </div>
                <input
                  className="email-input"
                  type="password"
                  name="confirmNewPassword"
                  value={confirm_password}
                  onChange={handleConfirmNewPasswordChange}
                  placeholder="Confirm New Password"
                  required
                />
              </div>
            </>
          )}

          {message && <div className="success-message">{message}</div>}
          {error && <div className="email-error">{error}</div>}

          <button className="email-submit-button" type="submit">
            {step === 1 ? 'Get OTP' : step === 2 ? 'Verify OTP' : 'Change Password'}
          </button>
        </form>
      </div>
    </>
  );
}
