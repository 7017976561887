import React from 'react';
import './Ourteam.css';
import Header from '../Header/Header';

const TeamComponent = () => {
  const teamMembers = [
    { name: 'Prathamesh Kinayekar', role: 'Back-end Developer', img: require('./../../Images/img/prathamesh.webp') },
    { name: 'Mallikarjun Mudapaki', role: 'Front-end Developer', img: require('./../../Images/img/mallikarjun.webp') },
    { name: 'Sneha Ekbote', role: 'DevOps Engineer', img: require('./../../Images/img/sneha.webp') },
    { name: 'Jayashree Patil', role: 'QA Engineer', img: require('./../../Images/img/jayashree.webp') },
    { name: 'Avantika Patil', role: 'Data Analyst', img: require('./../../Images/img/avantika.webp') },
  ];
  const teamLeadership = [
    { name: 'Anil Chavan ', role: 'Founder & CEO', img: require('./../../Images/img/Anil.webp') },
    { name: 'SaiKumar Badiger', role: 'Human Resources(HR)', img: require('./../../Images/img/Saikumar.webp') },
    { name: 'Mallesh Nashi', role: 'Fleet Manager', img: require('./../../Images/img/Mallesh.webp') },
  ]

  return (
    <>
    <Header/>
    <div className='ourteam-main-container'>
 
   
     <div className="ourteam-container">
      <h2 className="ourteam-title">Meet The <span className="ourteam-highlight">Team</span></h2>
      <div  className="Ourleadership-team">
        {teamLeadership.map((member, index) => (
          <div className="ourteam-card" key={index}>
            <img src={member.img} alt={member.name} className="ourteam-image" />
            <div className="ourteam-name">{member.name}</div>
            <div className="ourteam-role">{member.role}</div>
          </div>
        ))}
      </div>
    </div>
    <div className="ourteam-container">
      {/* <h2 className="ourteam-title">Meet The <span className="ourteam-highlight">Team</span></h2> */}
      <div className="ourteam-team-container">
        {teamMembers.map((member, index) => (
          <div className="ourteam-card" key={index}>
            <img src={member.img} alt={member.name} className="ourteam-image" />
            <div className="ourteam-name">{member.name}</div>
            <div className="ourteam-role">{member.role}</div>
          </div>
        ))}
      </div>
    </div>
    </div>
    </>
  );
};

export default TeamComponent;
