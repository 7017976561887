import React from 'react';
import './OurClients.css';

import logo1 from '../../Images/img/atulpurohit.webp';
import logo2 from '../../Images/img/bakeaway.webp';
import logo3 from '../../Images/img/cakelicious.webp';
import logo4 from '../../Images/img/cakeroyale.webp';
// import logo5 from '../../Images/cakesroyale.png';
import logo6 from '../../Images/img/icecreamdelights.webp';
import logo7 from '../../Images/img/mokarrt.webp';
import logo8 from '../../Images/img/pouterbakes.webp';
// import logo9 from '../../Images/img/thecakehub.webp';
import logo10 from '../../Images/img/thecakehub.webp';
import logo11 from '../../Images/img/AIBH.webp';
import logo12 from '../../Images/img/Rasaraj.webp';
import logo13 from '../../Images/img/Ruchira.webp';
import logo14 from '../../Images/img/kakewalk.webp';
import logo15 from '../../Images/img/PaiMeatnEat.webp';



const logos = [logo1, logo2, logo3, logo4, logo6, logo7, logo8, logo10, logo11,logo12,logo13,logo14,logo15];

function Clients() {
  return (
    <div className="slider-container">
      <div className="slider-track">
        {logos.map((logo, index) => (
          <div key={index} className="slide">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
        {logos.map((logo, index) => (
          <div key={index + logos.length} className="slide">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
        {logos.map((logo, index) => (
          <div key={index + logos.length} className="slide">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
        
      </div>
    </div>
  );
}

export default Clients;

